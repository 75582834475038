* {
  box-sizing: border-box;
}

h2 {
  color: rgb(255, 255, 204);
}

.App {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.company-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.company-header-logo {
  margin-right: 1em;
}

.company-header-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

@media only screen and (max-width:500px) {
  .company-header-text {
    width: 50%;
    overflow-wrap: break-word;
  }
  
  .App {
    transition: background-color 0.1s;
  }
}